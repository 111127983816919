import { useGtm } from "@gtm-support/vue-gtm";
import countryList from "@/constant/countryList";

export const formatCountry = (country) => {
  const countryItem = countryList.find((item) => item.value === country);
  return countryItem;
};

export function isMobile() {
  let res = false;
  const clientWidth = window.innerWidth;
  const ua = navigator.userAgent.toLowerCase();
  const isMobileUa = /mobile|android|iphone|ipod|phone|ipad/i.test(ua);
  if ((clientWidth && clientWidth <= 864) || isMobileUa) {
    res = true;
  }
  return res;
}

export function tracking(event: any) {
  const gtm = useGtm();
  gtm.trackEvent({
    event: "mingtest",
  });
}
