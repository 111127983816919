import { defineStore } from "pinia";
import { cloneDeep, forEach } from "lodash";

export const useRfqListStore = defineStore("rfqList", {
  state: () => {
    return {
      rfqList: [],
      showDrawer: false,
    };
  },
  actions: {
    setRfqList(value: any[]) {
      const list = cloneDeep(value);
      list.forEach((item) => {
        if (!item.targetPrice) {
          item.targetPrice = null;
        }
        if (!item.customerRequirements) {
          item.customerRequirements = null;
        }
      });
      this.rfqList = value;
    },
    addRfq(rfq) {
      const idx = this.rfqList.findIndex((item) => {
        return item.id === rfq.id;
      });
      if (idx > -1) {
        this.rfqList[idx].num = rfq.num || 0;
      } else {
        this.rfqList.unshift({
          ...{
            targetPrice: null,
            customerRequirements: null,
          },
          ...rfq,
        });
      }
    },
    removeRfq(rfq) {
      const idx = this.rfqList.findIndex((item) => item.id === rfq.id);
      this.rfqList.splice(idx, 1);
    },
    setShowDrawer(val) {
      this.refreshData();

      this.showDrawer = val;
    },
    refreshData() {
      let rfq: any = localStorage.getItem("rfq");
      if (rfq) {
        rfq = JSON.parse(rfq);
        this.rfqList = rfq.rfqList;
      }
    },
  },
  persist: {
    enabled: true, // 启用插件
    strategies: [
      {
        key: "rfq",
        storage: localStorage,
      },
    ],
  },
});
