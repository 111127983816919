import { apiRequest, cacheRequest } from "./base";

export const getCategory = () => {
  const res = cacheRequest.get("/v2/mall/sku/category");
  return res;
};

export const getManufacturer = () => {
  const res = cacheRequest.get(`/v2/mall/sku/mfr`);
  return res;
};

export const getManufacturerDetail = (mfrTag) => {
  const res = cacheRequest.get(`/v2/mall/sku/mfr/${mfrTag}`);
  return res;
};

export const getHotSale = () => {
  const res = apiRequest.get(`/v2/mall/sku/hot/sale`);
  return res;
};

export const sendMessageBoard = (data) => {
  const res = apiRequest.post(`/v1/message-board`, data);
  return res;
};
