import {
  createRouter,
  createWebHistory,
  RouterOptions,
  Router,
  RouteRecordRaw,
} from "vue-router";
import { Search } from "@element-plus/icons-vue/dist/types";
import LayoutPC from "@/layout/site.vue";
import LayoutH5 from "@/layoutH5/site.vue";
import { useUserStore } from "@/store/user";
import { isMobile } from "@/util/tool";
import { setPageLoading } from "@/util/user";

// PC

const Home = () => import("@/pages/home/index.vue");
const Category = () => import("@/pages/category/index.vue");
const Manufacturer = () => import("@/pages/manufacturer/index.vue");
const ManufacturerDetail = () =>
  import("@/pages/manufacturer-detail/index.vue");
const ProductList = () => import("@/pages/product-list/index.vue");
const RFQ = () => import("@/pages/rfq/index.vue");
const Product = () => import("@/pages/product-detail/index.vue");
const User = () => import("@/pages/user/index.vue");
const Settings = () => import("@/pages/user/settings/index.vue");
const RfqHistory = () => import("@/pages/user/rfq/index.vue");
const Like = () => import("@/pages/user/like/index.vue");
const AboutUs = () => import("@/pages/about-us/index.vue");
const ContactUs = () => import("@/pages/contact-us/index.vue");
const PrivacyPolicy = () => import("@/pages/policy/privacy.vue");
const CookiePolicy = () => import("@/pages/policy/cookie.vue");
const TermOfUse = () => import("@/pages/policy/term.vue");
const Blog = () => import("@/pages/blog/index.vue");
const BlogDetail = () => import("@/pages/blog-detail/index.vue");
const NotFound = () => import("@/pages/404.vue");
const Quality = () => import("@/pages/quality/index.vue");

// H5

const HomeH5 = () => import("@/pagesH5/home/index.vue");
const RFQH5 = () => import("@/pagesH5/rfq/index.vue");
const CategoryH5 = () => import("@/pagesH5/category/index.vue");
const ManufacturerH5 = () => import("@/pagesH5/manufacturer/index.vue");
const ManufacturerDetailH5 = () =>
  import("@/pagesH5/manufacturer-detail/index.vue");
const PrivacyPolicyH5 = () => import("@/pagesH5/policy/privacy.vue");
const CookiePolicyH5 = () => import("@/pagesH5/policy/cookie.vue");
const TermOfUseH5 = () => import("@/pagesH5/policy/term.vue");
const BlogH5 = () => import("@/pagesH5/blog/index.vue");
const BlogDetailH5 = () => import("@/pagesH5/blog-detail/index.vue");
const AboutUsH5 = () => import("@/pagesH5/about-us/index.vue");
const ContactUsH5 = () => import("@/pagesH5/contact-us/index.vue");
const ProductH5 = () => import("@/pagesH5/product-detail/index.vue");
const LoginH5 = () => import("@/pagesH5/login/index.vue");
const ProductListH5 = () => import("@/pagesH5/product-list/index.vue");
const NotFoundH5 = () => import("@/pagesH5/404.vue");
const QualityH5 = () => import("@/pagesH5/quality/index.vue");

const isH5 = isMobile();
export const createVueRouter = () => {
  const routes: RouteRecordRaw[] = [
    {
      // path: "/:lang?(en|ru|zh_hans|zh_hant)",
      path: "/:lang(en|ru|zh_hans|zh_hant)?",
      component: isH5 ? LayoutH5 : LayoutPC,
      children: [
        {
          path: "",
          name: "home",
          component: isH5 ? HomeH5 : Home,
        },
        {
          path: "category/:name?",
          name: "category",
          component: isH5 ? CategoryH5 : Category,
        },
        {
          path: "category/:name/:sub_category",
          name: "category-sku",
          component: isH5 ? ProductListH5 : ProductList,
        },
        {
          path: "manufacturer",
          name: "manufacturer",
          component: isH5 ? ManufacturerH5 : Manufacturer,
        },
        {
          path: "manufacturer/:urltag",
          name: "manufacturer-detail",
          component: isH5 ? ManufacturerDetailH5 : ManufacturerDetail,
        },
        {
          path: "manufacturer/:name/:category",
          name: "manufacturer-category-sku",
          component: isH5 ? ProductListH5 : ProductList,
        },
        {
          path: "search/:keyword?",
          name: "search",
          component: isH5 ? ProductListH5 : ProductList,
        },
        {
          path: "product-detail/:sku?",
          name: "detail",
          component: isH5 ? ProductH5 : Product,
        },
        {
          path: "rfq",
          name: "rfq",
          component: isH5 ? RFQH5 : RFQ,
        },
        {
          path: "blog",
          name: "blog",
          component: isH5 ? BlogH5 : Blog,
        },
        {
          path: "blog/:slug",
          name: "blog-detail",
          component: isH5 ? BlogDetailH5 : BlogDetail,
        },
        {
          path: "about-us",
          name: "about-us",
          component: isH5 ? AboutUsH5 : AboutUs,
        },
        {
          path: "contact-us",
          name: "contact-us",
          component: isH5 ? ContactUsH5 : ContactUs,
        },
        {
          path: "privacy-policy",
          name: "privacy-policy",
          component: isH5 ? PrivacyPolicyH5 : PrivacyPolicy,
        },
        {
          path: "cookie-policy",
          name: "cookie-policy",
          component: isH5 ? CookiePolicyH5 : CookiePolicy,
        },
        {
          path: "term-of-use",
          name: "term-of-use",
          component: isH5 ? TermOfUseH5 : TermOfUse,
        },
        {
          path: "quality-control",
          name: "quality-control",
          meta: {
            hidePcHeader: true,
          },
          component: isH5 ? QualityH5 : Quality,
        },

        {
          path: "login",
          name: "login",
          component: LoginH5,
        },
        {
          path: "user",
          name: "user",
          component: User,
          meta: {
            needLogin: true,
          },
          redirect: {
            name: "user-rfq",
          },
          children: [
            {
              path: "rfq",
              name: "user-rfq",
              component: RfqHistory,
            },
            {
              path: "like",
              name: "user-favorite",
              component: Like,
            },
          ],
        },
        {
          path: "user/settings",
          name: "settings",
          component: Settings,
        },
        {
          path: "404",
          name: "404",
          component: isH5 ? NotFoundH5 : NotFound,
        },
      ],
    },

    {
      path: "/:catchAll(.*)",
      meta: {
        hideMenu: true,
      },
      redirect: "/404",
    },
  ];

  const options: RouterOptions = {
    history: createWebHistory(),
    routes,
  };

  const router: Router = createRouter(options);

  router.beforeEach((to, from, next) => {
    const store = useUserStore();
    if (to.meta.needLogin && !store.userInfo.userId) {
      next({
        name: "home",
        query: {
          path: window.location.pathname,
        },
      });
    }
    if (to.path !== from.path) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    if (to.name === "detail") {
      setPageLoading(true);
    }
    next();
  });
  return router;
};

// export default router;
