export const encodeUrl = (url) => {
  const regex = /[^a-zA-Z0-9]+/g;
  const res = url.replace(regex, "-").replace(/-+/g, "-").replace(/^-|-$/g, "");
  return res;
};

export const transImgUrl = (url) => {
  const res = `https://file.aichiplink.com${url}`;
  return res;
};
