const langMap = {
  en: {
    label: "EN",
    value: "en",
    apiKey: "en",
  },
  ru: {
    label: "Русскийязык",
    value: "ru",
    apiKey: "ru",
  },
  zh_hans: {
    label: "简体中文",
    value: "zh_hans",
    apiKey: "zh-Hans",
  },
  zh_hant: {
    label: "繁体中文",
    value: "zh_hant",
    apiKey: "zh-Hant",
  },
};

export const defaultLang = "en";

export default langMap;
