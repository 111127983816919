import { isMobile } from "@/util/tool";

const pageType = isMobile() ? "H5" : "PC";

export default {
  mounted(el, binding) {
    const value = binding.value || {};
    let action = value.action || "";
    const event = value.event || "";
    if (action.constructor !== Array) {
      action = [action];
    }
    // 定义一个通用的推送方法
    const pushToDataLayer = (action) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event,
          action,
          pageType,
        });
      }
    };

    // 点击事件监听器
    if (action.includes("click")) {
      const handleClick = () => pushToDataLayer("click");
      el.trackClickHandler = handleClick;
      el.addEventListener("click", handleClick);
    }

    // 曝光事件监听器
    if (action.includes("show")) {
      const handleExposure = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            pushToDataLayer("show");
            observer.unobserve(el);
          }
        });
      };

      const observer = new IntersectionObserver(handleExposure);
      el.trackObserver = observer;
      observer.observe(el);
    }
  },
  unmounted(el) {
    // 移除点击事件监听器
    if (el.trackClickHandler) {
      el.removeEventListener("click", el.trackClickHandler);
      delete el.trackClickHandler;
    }

    // 断开曝光观察者
    if (el.trackObserver) {
      el.trackObserver.disconnect();
      delete el.trackObserver;
    }
  },
};
