import { apiRequest } from "./base";

export const getUserInfo = async () => {
  const res = await apiRequest.get("/v1/mall/user-info");
  return res;
};

export const updateUserInfo = async (data) => {
  const res = await apiRequest.put("/v1/mall/user-info", data);
  return res;
};

export const getUser = async () => {
  const res = await apiRequest.get("/v1/mall/user-info");
  return res;
};

export const login = async (data) => {
  const res = await apiRequest.post(`/v1/login`, data);
  localStorage.setItem("token", res?.token || "");

  return res;
};
export const logout = async () => {
  const res = await apiRequest.post(`/v1/logout`);
  localStorage.removeItem("token");
  return res;
};

export const getRfqList = async (params) => {
  const res = await apiRequest.get(`/v1/rfq/listRfq`, { params });
  return res;
};

export const getLikeList = async () => {
  const res = await apiRequest.get(`/v2/mall/favorite`);
  return res;
};

export const getLike = async (data) => {
  const res = await apiRequest.post(`/v2/mall/sku/favorite`, data);
  return res;
};

export const Like = async (data) => {
  const res = await apiRequest.post(`/v2/mall/favorite`, data);
  return res;
};

export const UnLike = async (params) => {
  const res = await apiRequest.delete(`/v1/mall/favorite`, { params });
  return res;
};

export const getAddressList = async (params) => {
  const res = await apiRequest.get(`/v1/mall/company-address`, { params });
  return res;
};

export const createAddress = async (data) => {
  const res = await apiRequest.post(`/v1/mall/company-address`, data);
  return res;
};

export const editAddress = async (id, data) => {
  const res = await apiRequest.put(`/v1/mall/company-address/${id}`, data);
  return res;
};

export const setPwd = async (data) => {
  const res = await apiRequest.post(`/v1/mall/change-password`, data);
  return res;
};

export const delAddress = async (data) => {
  const res = await apiRequest.delete(`/v1/mall/company-address`, { data });
  return res;
};

export const uploadLicense = async (file) => {
  const param = new FormData();
  param.append("type", "1");
  param.append("source", "2");
  param.append("file", file);
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const res = await apiRequest.post(`/v1/public/uploadFile`, param, config);
  return res;
};

export const checkEmail = async (params) => {
  const res = await apiRequest.get(`/v1/mall/login/checkEmail`, {
    params,
  });
  return res;
};

export const sendCode = async (params) => {
  const res = await apiRequest.get(`/v1/mall/code/send2`, {
    params,
  });
  return res;
};

export const registerByCode = async (data) => {
  const res = await apiRequest.post(`/v1/mall/register2`, data);
  return res;
};

export const forgetByCode = async (data) => {
  const res = await apiRequest.post(`/v1/mall/forget-password2`, data);
  return res;
};

export const checkCode = async (params) => {
  const res = await apiRequest.get(`/v1/mall/login/checkCode`, { params });
  return res;
};

export const changeLoginEmail = async (data) => {
  const res = await apiRequest.post(`/v1/mall/change-login-email`, data);
  return res;
};
