import { defineStore } from "pinia";

export const useHistoryStore = defineStore("history", {
  state: () => {
    return {
      history: [],
    };
  },
  actions: {
    pushHistory(keyword) {
      if (this.history.includes(keyword)) {
        return;
      }
      if (this.history.length >= 10) {
        this.history.pop();
      }
      this.history.unshift(keyword);
    },
  },
  persist: {
    enabled: true, // 启用插件
    strategies: [
      {
        key: "history",
        storage: localStorage,
      },
    ],
  },
});
