const countryList = [
  { icon: "🇦🇫", label: "Afghanistan", value: "AF" },
  { icon: "🇦🇱", label: "Albania", value: "AL" },
  { icon: "🇩🇿", label: "Algeria", value: "DZ" },
  { icon: "🇦🇩", label: "Andorra", value: "AD" },
  { icon: "🇦🇴", label: "Angola", value: "AO" },
  { icon: "🇦🇮", label: "Anguilla", value: "AI" },
  { icon: "🇦🇷", label: "Argentina", value: "AR" },
  { icon: "🇦🇲", label: "Armenia", value: "AM" },
  { icon: "🇦🇼", label: "Aruba", value: "AW" },
  { icon: "🇦🇺", label: "Australia", value: "AU" },
  { icon: "🇦🇹", label: "Austria", value: "AT" },
  { icon: "🇦🇿", label: "Azerbaijan", value: "AZ" },
  { icon: "🇧🇸", label: "Bahamas", value: "BS" },
  { icon: "🇧🇭", label: "Bahrain", value: "BH" },
  { icon: "🇧🇩", label: "Bangladesh", value: "BD" },
  { icon: "🇧🇧", label: "Barbados", value: "BB" },
  { icon: "🇧🇾", label: "Belarus", value: "BY" },
  { icon: "🇧🇪", label: "Belgium", value: "BE" },
  { icon: "🇧🇿", label: "Belize", value: "BZ" },
  { icon: "🇧🇯", label: "Benin", value: "BJ" },
  { icon: "🇧🇲", label: "Bermuda", value: "BM" },
  { icon: "🇧🇹", label: "Bhutan", value: "BT" },
  { icon: "🇧🇴", label: "Bolivia", value: "BO" },
  { icon: "🇧🇦", label: "Bosnia and Herzegovina", value: "BA" },
  { icon: "🇧🇼", label: "Botswana", value: "BW" },
  { icon: "🇧🇷", label: "Brazil", value: "BR" },
  { icon: "🇧🇳", label: "Brunei Darussalam", value: "BN" },
  { icon: "🇧🇬", label: "Bulgaria", value: "BG" },
  { icon: "🇧🇫", label: "Burkina Faso", value: "BF" },
  { icon: "🇧🇮", label: "Burundi", value: "BI" },
  { icon: "🇨🇻", label: "Cabo Verde", value: "CV" },
  { icon: "🇰🇭", label: "Cambodia", value: "KH" },
  { icon: "🇨🇲", label: "Cameroon", value: "CM" },
  { icon: "🇨🇦", label: "Canada", value: "CA" },
  { icon: "🇨🇫", label: "Central African Republic", value: "CF" },
  { icon: "🇹🇩", label: "Chad", value: "TD" },
  { icon: "🇨🇱", label: "Chile", value: "CL" },
  { icon: "🇨🇳", label: "China", value: "CN" },
  { icon: "🇨🇴", label: "Colombia", value: "CO" },
  { icon: "🇰🇲", label: "Comoros", value: "KM" },
  { icon: "🇨🇩", label: "Congo", value: "CD" },
  { icon: "🇨🇬", label: "Congo", value: "CG" },
  { icon: "🇨🇷", label: "Costa Rica", value: "CR" },
  { icon: "🇭🇷", label: "Croatia", value: "HR" },
  { icon: "🇨🇺", label: "Cuba", value: "CU" },
  { icon: "🇨🇾", label: "Cyprus", value: "CY" },
  { icon: "🇨🇿", label: "Czechia", value: "CZ" },
  { icon: "🇨🇮", label: "Cote d Ivoire", value: "CI" },
  { icon: "🇩🇰", label: "Denmark", value: "DK" },
  { icon: "🇩🇯", label: "Djibouti", value: "DJ" },
  { icon: "🇩🇲", label: "Dominica", value: "DM" },
  { icon: "🇩🇴", label: "Dominican Republic", value: "DO" },
  { icon: "🇪🇨", label: "Ecuador", value: "EC" },
  { icon: "🇪🇬", label: "Egypt", value: "EG" },
  { icon: "🇸🇻", label: "El Salvador", value: "SV" },
  { icon: "🇪🇷", label: "Eritrea", value: "ER" },
  { icon: "🇪🇪", label: "Estonia", value: "EE" },
  { icon: "🇸🇿", label: "Eswatini", value: "SZ" },
  { icon: "🇪🇹", label: "Ethiopia", value: "ET" },
  { icon: "🇫🇯", label: "Fiji", value: "FJ" },
  { icon: "🇫🇮", label: "Finland", value: "FI" },
  { icon: "🇫🇷", label: "France", value: "FR" },
  { icon: "🇬🇦", label: "Gabon", value: "GA" },
  { icon: "🇬🇲", label: "Gambia", value: "GM" },
  { icon: "🇬🇪", label: "Georgia", value: "GE" },
  { icon: "🇩🇪", label: "Germany", value: "DE" },
  { icon: "🇬🇭", label: "Ghana", value: "GH" },
  { icon: "🇬🇮", label: "Gibraltar", value: "GI" },
  { icon: "🇬🇷", label: "Greece", value: "GR" },
  { icon: "🇬🇱", label: "Greenland", value: "GL" },
  { icon: "🇬🇵", label: "Guadeloupe", value: "GP" },
  { icon: "🇬🇺", label: "Guam", value: "GU" },
  { icon: "🇬🇹", label: "Guatemala", value: "GT" },
  { icon: "🇬🇬", label: "Guernsey", value: "GG" },
  { icon: "🇬🇳", label: "Guinea", value: "GN" },
  { icon: "🇬🇼", label: "Guinea-Bissau", value: "GW" },
  { icon: "🇬🇾", label: "Guyana", value: "GY" },
  { icon: "🇭🇹", label: "Haiti", value: "HT" },
  { icon: "🇭🇳", label: "Honduras", value: "HN" },
  { icon: "🇭🇰", label: "Hong Kong", value: "HK" },
  { icon: "🇭🇺", label: "Hungary", value: "HU" },
  { icon: "🇮🇸", label: "Iceland", value: "IS" },
  { icon: "🇮🇳", value: "IN", label: "India" },
  { icon: "🇮🇩", value: "ID", label: "Indonesia" },
  { icon: "🇮🇷", value: "IR", label: "Iran" },
  { icon: "🇮🇶", value: "IQ", label: "Iraq" },
  { icon: "🇮🇪", value: "IE", label: "Ireland" },
  { icon: "🇮🇱", value: "IL", label: "Israel" },
  { icon: "🇮🇹", value: "IT", label: "Italy" },
  { icon: "🇯🇲", value: "JM", label: "Jamaica" },
  { icon: "🇯🇵", value: "JP", label: "Japan" },
  { icon: "🇯🇴", value: "JO", label: "Jordan" },
  { icon: "🇰🇿", value: "KZ", label: "Kazakhstan" },
  { icon: "🇰🇪", value: "KE", label: "Kenya" },
  { icon: "🇰🇵", value: "KP", label: "Korea" },
  { icon: "🇰🇷", value: "KR", label: "Korea" },
  { icon: "🇰🇼", value: "KW", label: "Kuwait" },
  { icon: "🇰🇬", value: "KG", label: "Kyrgyzstan" },
  { icon: "🇱🇻", value: "LV", label: "Latvia" },
  { icon: "🇱🇧", value: "LB", label: "Lebanon" },
  { icon: "🇱🇸", value: "LS", label: "Lesotho" },
  { icon: "🇱🇷", value: "LR", label: "Liberia" },
  { icon: "🇱🇾", value: "LY", label: "Libya" },
  { icon: "🇱🇮", value: "LI", label: "Liechtenstein" },
  { icon: "🇱🇹", value: "LT", label: "Lithuania" },
  { icon: "🇱🇺", value: "LU", label: "Luxembourg" },
  { icon: "🇲🇬", value: "MG", label: "Madagascar" },
  { icon: "🇲🇼", value: "MW", label: "Malawi" },
  { icon: "🇲🇾", value: "MY", label: "Malaysia" },
  { icon: "🇲🇻", value: "MV", label: "Maldives" },
  { icon: "🇲🇱", value: "ML", label: "Mali" },
  { icon: "🇲🇹", value: "MT", label: "Malta" },
  { icon: "🇲🇶", value: "MQ", label: "Martinique" },
  { icon: "🇲🇷", value: "MR", label: "Mauritania" },
  { icon: "🇲🇺", value: "MU", label: "Mauritius" },
  { icon: "🇲🇽", value: "MX", label: "Mexico" },
  { icon: "🇲🇩", value: "MD", label: "Moldova" },
  { icon: "🇲🇨", value: "MC", label: "Monaco" },
  { icon: "🇲🇳", value: "MN", label: "Mongolia" },
  { icon: "🇲🇪", value: "ME", label: "Montenegro" },
  { icon: "🇲🇸", value: "MS", label: "Montserrat" },
  { icon: "🇲🇦", value: "MA", label: "Morocco" },
  { icon: "🇲🇿", value: "MZ", label: "Mozambique" },
  { icon: "🇲🇲", value: "MM", label: "Myanmar" },
  { icon: "🇳🇦", value: "NA", label: "Namibia" },
  { icon: "🇳🇵", value: "NP", label: "Nepal" },
  { icon: "🇳🇱", value: "NL", label: "Netherlands" },
  { icon: "🇳🇨", value: "NC", label: "New Caledonia" },
  { icon: "🇳🇿", value: "NZ", label: "New Zealand" },
  { icon: "🇳🇮", value: "NI", label: "Nicaragua" },
  { icon: "🇳🇪", value: "NE", label: "Niger" },
  { icon: "🇳🇬", value: "NG", label: "Nigeria" },
  { icon: "🇳🇴", value: "NO", label: "Norway" },
  { icon: "🇴🇲", value: "OM", label: "Oman" },
  { icon: "🇵🇰", value: "PK", label: "Pakistan" },
  { icon: "🇵🇸", value: "PS", label: "Palestine" },
  { icon: "🇵🇦", value: "PA", label: "Panama" },
  { icon: "🇵🇬", value: "PG", label: "Papua New Guinea" },
  { icon: "🇵🇾", value: "PY", label: "Paraguay" },
  { icon: "🇵🇪", value: "PE", label: "Peru" },
  { icon: "🇵🇭", value: "PH", label: "Philippines" },
  { icon: "🇵🇱", value: "PL", label: "Poland" },
  { icon: "🇵🇹", value: "PT", label: "Portugal" },
  { icon: "🇵🇷", value: "PR", label: "Puerto Rico" },
  { icon: "🇶🇦", value: "QA", label: "Qatar" },
  { icon: "🇲🇰", value: "MK", label: "Macedonia" },
  { icon: "🇷🇴", value: "RO", label: "Romania" },
  { icon: "🇷🇺", value: "RU", label: "Russia" },
  { icon: "🇷🇼", value: "RW", label: "Rwanda" },
  { icon: "🇷🇪", value: "RE", label: "Réunion" },
  { icon: "🇸🇦", value: "SA", label: "Saudi Arabia" },
  { icon: "🇸🇳", value: "SN", label: "Senegal" },
  { icon: "🇷🇸", value: "RS", label: "Serbia" },
  { icon: "🇸🇬", value: "SG", label: "Singapore" },
  { icon: "🇸🇰", value: "SK", label: "Slovakia" },
  { icon: "🇸🇮", value: "SI", label: "Slovenia" },
  { icon: "🇸🇴", value: "SO", label: "Somalia" },
  { icon: "🇿🇦", value: "ZA", label: "South Africa" },
  { icon: "🇸🇸", value: "SS", label: "South Sudan" },
  { icon: "🇪🇸", value: "ES", label: "Spain" },
  { icon: "🇱🇰", value: "LK", label: "Sri Lanka" },
  { icon: "🇸🇩", value: "SD", label: "Sudan" },
  { icon: "🇸🇷", value: "SR", label: "Suriname" },
  { icon: "🇸🇪", value: "SE", label: "Sweden" },
  { icon: "🇨🇭", value: "CH", label: "Switzerland" },
  { icon: "🇸🇾", value: "SY", label: "Syria" },
  { icon: "🇹🇼", value: "TW", label: "Taiwan" },
  { icon: "🇹🇯", value: "TJ", label: "Tajikistan" },
  { icon: "🇹🇿", value: "TZ", label: "Tanzania" },
  { icon: "🇹🇭", value: "TH", label: "Thailand" },
  { icon: "🇹🇱", value: "TL", label: "Timor-Leste" },
  { icon: "🇹🇬", value: "TG", label: "Togo" },
  { icon: "🇹🇴", value: "TO", label: "Tonga" },
  { icon: "🇹🇳", value: "TN", label: "Tunisia" },
  { icon: "🇹🇷", value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan", icon: "🇹🇲" },
  { value: "UG", label: "Uganda", icon: "🇺🇬" },
  { value: "UA", label: "Ukraine", icon: "🇺🇦" },
  { value: "AE", label: "United Arab Emirates", icon: "🇦🇪" },
  { value: "GB", label: "United Kingdom", icon: "🇬🇧" },
  { value: "US", label: "United States", icon: "🇺🇸" },
  { value: "UY", label: "Uruguay", icon: "🇺🇾" },
  { value: "UZ", label: "Uzbekistan", icon: "🇺🇿" },
  { value: "VE", label: "Venezuela", icon: "🇻🇪" },
  { value: "VN", label: "Vietnam", icon: "🇻🇳" },
  { value: "YE", label: "Yemen", icon: "🇾🇪" },
  { value: "ZM", label: "Zambia", icon: "🇿🇲" },
  { value: "ZW", label: "Zimbabwe", icon: "🇿🇼" },
];

export default countryList;
