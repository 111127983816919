import { defineStore } from "pinia";

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      headerSearchIsShow: false,
      loginModal: {
        email: "",
        status: "",
      },
      keyword: "",
      pageIsLoading: false,
      loginModalIsShow: false,
    };
  },
  actions: {
    setHeaderSearchIsShow(val) {
      this.headerSearchIsShow = !!val;
    },
    setKeyword(val) {
      this.keyword = val;
    },
    setLoginModalIsShow(val, data = {}) {
      const { email, status } = data as any;
      this.loginModalIsShow = val;
      this.loginModal.email = email || "";
      this.loginModal.status = status || "";
    },
    setPageLoading(val) {
      this.pageIsLoading = val;
    },
  },
});
