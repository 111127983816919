import { defineStore } from "pinia";
import queryString from "query-string";
import { cloneDeep } from "lodash";

export const useProductStore = defineStore("product", {
  state: () => {
    return {
      displayType: "table",
      filterValue: {},
      submitFilter: {},
      filterEnv: {
        pathname: undefined,
        cid: undefined,
        mid: undefined,
      },
    };
  },
  actions: {
    setDisplayTyp(value: string) {
      this.displayType = value;
    },
    setFilterValue(value) {
      const parsed = queryString.parse(location.search);
      this.filterEnv.pathname = window.location.pathname;
      this.filterEnv.cid = parsed?.cid;
      this.filterEnv.mid = parsed?.mid;
      this.filterValue = value;
    },
    setSubmitFilterValue(value) {
      const parsed = queryString.parse(location.search);
      this.filterEnv.pathname = window.location.pathname;
      this.filterEnv.cid = parsed?.cid;
      this.filterEnv.mid = parsed?.mid;
      this.filterValue = value;
      this.submitFilter = value;
    },
    cleanFilterVlaue() {
      this.filterValue = {};
      this.submitFilter = {};
      this.filterEnv = {
        pathname: "",
        cid: "",
      };
    },
    checkFilterValue() {
      const parsed = queryString.parse(location.search);
      const { pathname } = window.location;
      const cid = parsed?.cid;
      const mid = parsed?.mid;
      if (
        this.filterEnv.pathname !== pathname ||
        this.filterEnv.cid !== cid ||
        this.filterEnv.mid !== mid
      ) {
        this.filterValue = {};
        this.submitFilter = {};
      }
      const url = new URL(window.location.href);
      let urlMid: any = url.searchParams.get("mid");
      if (urlMid) {
        urlMid = urlMid.split(";").map((item) => Number(item));
        this.filterValue.Manufacturer = urlMid;
        this.submitFilter.Manufacturer = urlMid;
      }
    },
    getSubmitFilterValue() {
      const res = {
        manufacturerIds: [],
        attributes: [],
      };
      const url = new URL(window.location.href);
      let urlMid: any = url.searchParams.get("mid");
      if (urlMid) {
        urlMid = urlMid.split(";").map((item) => Number(item));
        res.manufacturerIds = urlMid;
      }
      const parsed = queryString.parse(location.search);
      const { pathname } = window.location;
      const cid = parsed?.cid;
      const mid = parsed?.mid;
      if (
        this.filterEnv.pathname === pathname &&
        this.filterEnv.cid === cid &&
        this.filterEnv.mid === mid
      ) {
        const submitFilter = cloneDeep(this.submitFilter);
        Object.keys(submitFilter).forEach((filterName) => {
          const value = submitFilter[filterName];
          if (value && value.length) {
            if (filterName === "Manufacturer") {
              res.manufacturerIds = value;
            } else {
              res.attributes.push({
                attributeName: filterName,
                attributeValues: value,
              });
            }
          }
        });
      }
      return res;
    },
    setManufaturer(val) {
      // const filter = this.filterValue;
      this.filterValue.Manufacturer = val;
    },
  },
  persist: {
    enabled: true, // 启用插件
  },
});
