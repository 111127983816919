import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  setup: function setup() {
    var _useI18n = useI18n({
        useScope: "global"
      }),
      t = _useI18n.t,
      locale = _useI18n.locale;
  }
});