import { useUserStore } from "@/store/user";
import { useGlobalStore } from "@/store/global";
import { getUserInfo, getCompany } from "@/api/user";
import { getCategory } from "@/api/home";

export const getGlobalUser = async () => {
  const token = localStorage.getItem("token");
  const userStore = useUserStore();
  const user: any = await getUserInfo();
  if (user.code === 200) {
    userStore.setUserInfo(user.data || {});
  } else {
    userStore.setUserInfo({});
    localStorage.removeItem("token");
  }
};

export const getCategoryList = async () => {
  const userStore = useUserStore();
  const res: any = await getCategory();
  userStore.setCategoryList(res.data || {});
};

export const setLoginModalIsShow = (val, data = {}) => {
  const globalStore = useGlobalStore();
  globalStore.setLoginModalIsShow(val, data as any);
};

export const setPageLoading = (val) => {
  const globalStore = useGlobalStore();
  globalStore.setPageLoading(val);
};
