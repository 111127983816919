import Axios, { AxiosInstance } from "axios";
import { useRouter } from "vue-router";
import { createVueRouter } from "@/router";
import langMap, { defaultLang } from "@/constant/lang";

const router = useRouter();

const { protocol, host }: Location = window.location;
const baseURL: string = `${protocol}//${host}${process.env.basePublicPath}`;
// const baseURL: string = `https://dev.icdatas.com`;
// const token =
//   "eyJhbGciOiJIUaI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhc2NvcGUiOiIiLCJleHAiOjQ4NTc5MjA4ODQsImlkZW50aXR5Ijo4NywibmljZSI6IjgyNjg3MTIzMEBxcS5jb20iLCJvcmlnX2lhdCI6MTcwNDI4NDg4NCwicm9sZWlkIjozLCJyb2xla2V5IjoiY3VzdG9tZXIiLCJyb2xlbmFtZSI6IuWuouaItyJ9.IoTlJ3m27HVVddgh6jQuYTF2EKDdtB9QQ9FURnTxN7k";
const apibBaseURL: string = `${window.location.origin}/api`;
export const apiBaseRequestURL = apibBaseURL;
export const apiRequest = Axios.create({
  baseURL: apibBaseURL,
  timeout: 100000, // 超时时间
  withCredentials: true, // 允许携带cookie
});
// request拦截器
apiRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const router = createVueRouter();
    const currentRoute = router.resolve(window.location.pathname);
    const langKey = (currentRoute.params.lang as string) || defaultLang;
    const lang = langMap[langKey].apiKey;
    if (!config?.params) {
      config.params = {};
    }
    config.params.lang = lang;
    config.headers["Chip-Language"] = lang;
    config.headers["Skip-Cloudflare-Cache"] = 1;
    return config;
  },
  (error) => {
    console.log(error);
    // return error;
    Promise.reject(error);
  },
);
apiRequest.interceptors.response.use(
  (response) => {
    const res = response && response.data && response.data;
    // // code 为401时，表示后端登录校验失败
    // if (res && res.code === 401) {
    //   window.a = useRouter();
    //   debugger;
    //   router.push({
    //     path: "/login",
    //   });
    // }
    // } else if (!res || res.code !== 200) {
    //   ElMessage.error(res.msg || "服务端报错");
    //   throw response.data;
    // }
    return res;
  },
  (error) => {
    const status: number = error?.response?.status;
    return error;
  },
);

export const i18nRequest: AxiosInstance = Axios.create({
  baseURL,
  timeout: 10 * 1000,
});

export const cacheRequest: AxiosInstance = Axios.create({
  baseURL: apibBaseURL,
  timeout: 100000, // 超时时间
  withCredentials: true, // 允许携带cookie
});

cacheRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const router = createVueRouter();
    const currentRoute = router.resolve(window.location.pathname);
    const langKey = (currentRoute.params.lang as string) || defaultLang;
    const lang = langMap[langKey].apiKey;
    if (!config?.params) {
      config.params = {};
    }
    config.params.lang = lang;
    config.headers["Chip-Language"] = lang;
    return config;
  },
  (error) => {
    console.log(error);
    // return error;
    Promise.reject(error);
  },
);
cacheRequest.interceptors.response.use(
  (response) => {
    const res = response && response.data && response.data;
    // // code 为401时，表示后端登录校验失败
    // if (res && res.code === 401) {
    //   window.a = useRouter();
    //   debugger;
    //   router.push({
    //     path: "/login",
    //   });
    // }
    // } else if (!res || res.code !== 200) {
    //   ElMessage.error(res.msg || "服务端报错");
    //   throw response.data;
    // }
    return res;
  },
  (error) => {
    const status: number = error?.response?.status;
    return error;
  },
);
