import { defineStore } from "pinia";
import { cloneDeep, forEach } from "lodash";
import Avatar from "@/assets/img/avatar.png";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      userInfo: {},
      comapny: {},
      categoryList: [],
    };
  },
  actions: {
    setUserInfo(user) {
      if (!user.avatar) {
        user.avatar = Avatar;
      }
      this.userInfo = user;
    },
    setComapny(comapny) {
      this.comapny = comapny;
    },
    setCategoryList(categoryList) {
      this.categoryList = categoryList.list;
    },
    getCategory(categoryId) {
      let res = null;
      const { categoryList } = this;
      categoryList.forEach((category) => {
        if (category.categoryId === categoryId) {
          res = category;
        }
        category.categories.forEach((subCategory) => {
          if (subCategory.categoryId === categoryId) {
            res = subCategory;
          }
        });
      });
      return res;
    },
    getCategoryByClassifyId(categoryId) {
      let res = null;
      const { categoryList } = this;
      categoryList.forEach((category) => {
        if (category.categoryId === categoryId) {
          res = category;
        }
        category.categories.forEach((subCategory) => {
          if (subCategory.categoryId === categoryId) {
            res = subCategory;
          }
        });
      });
      return res;
    },
  },
});
