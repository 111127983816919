import { VueMessageType } from "vue-i18n";
import { LocaleMessage } from "@intlify/core-base";
import { i18nRequest } from "./base";

export async function fetchTranslation(
  lang: string,
): Promise<LocaleMessage<VueMessageType>> {
  const response = await i18nRequest.get(
    // `https://cdn.aichiplink.com/static/translation/${lang}.json`,
    `static/translation/${lang}.json`,
    {
      params: {
        _t: process.env.buildTime,
      },
    },
  );
  return response.data;
}
